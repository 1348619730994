export interface MakeRequestParams {
    method: 'GET' | 'POST' | 'DELETE' | 'PATCH'
    path: string
    body?: object
    fatalError?: boolean
    options?: {
        timeout?: number
    }
    nuxtApiRoute?: boolean
    mock?: boolean
    signal?: AbortSignal
}

export async function getHeaders({
    contentType = 'application/json',
}: {
    contentType?: string
} = {}) {
    const session = useSupabaseSession()
    const jwt = session.value?.access_token

    return {
        'Content-Type': contentType,
        ...(jwt && {
            Authorization: `Bearer ${jwt}`,
        }),
    }
}

export async function createAnonymousUserIfNecessary() {
    const session = useSupabaseSession()
    const client = useSupabaseClient()

    if (!session.value?.user) {
        await client.auth.signInAnonymously()
    }
}

type NuxtFetchResponse = Response

export async function makeRequest({
    method,
    body,
    path,
    signal,
    fatalError = true,
    options = {},
    mock = false,
    nuxtApiRoute = false,
}: MakeRequestParams) {
    const config = useRuntimeConfig()
    const { timeout = 13000 } = options // 13 sec timeout because vercel's default is 15 sec

    try {
        /**
         * The Tanstack Query could potentially set the signal,
         * but if it doesn't we set our own timeout manually
         */
        const startTime = Date.now()
        let finalSignal = signal
        let timeoutTimerId
        if (!finalSignal) {
            const ac = new AbortController()
            finalSignal = ac.signal
            timeoutTimerId = setTimeout(() => ac.abort(), timeout)
        }

        const base = mock
            ? `/api/mock`
            : nuxtApiRoute
              ? `/api`
              : config.public.apiHost

        const fetchUrl = `${base}${path}`

        const response = (await $fetch<unknown>(fetchUrl, {
            method,

            headers: await getHeaders(),
            ...(body && {
                body: JSON.stringify(body),
            }),
            signal: finalSignal,
        })) as NuxtFetchResponse

        if (timeoutTimerId) {
            clearTimeout(timeoutTimerId)
        }
        const endTime = Date.now()
        const duration = endTime - startTime
        console.log(`${method} ${path} - ${duration}ms`)

        return response as any
    } catch (error: any) {
        if (signal?.aborted && import.meta.client) {
        } else {
            console.log(`throwing createError`, error?.message)
            throw createError({
                statusCode: error.statusCode,
                statusMessage: `[${method} ${path}]: ${error.message}`,
                fatal: fatalError,
            })
        }
    }
}
